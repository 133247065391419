import React from 'react';

interface PlannerLogoProps {
  width?: number;
  height?: number;
}

const defaultProps = {
  height: 28,
  isInverted: false
};

const PlannerLogo: React.FC<PlannerLogoProps> = (props) => {
  const width = Math.round(267 / 40 * (props.height || 28));
  return (
    <svg width={width} height={props.height} viewBox="0 0 267 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M110.345 3.13232H103.083L103.226 31.1813C103.226 32.6051 103.226 38.585 111.342 38.8698C114.332 38.8698 116.182 37.5884 116.182 37.5884V31.8932C110.914 33.1746 110.345 30.8965 110.345 29.3303V15.9466H116.04V10.2513H110.345V3.13232Z" fill="black"/>
      <path d="M125.295 10.2515H118.033L128.569 38.4428H135.119L142.095 19.3638L149.214 38.4428H155.764L166.015 10.2515H159.039L152.347 29.7576L145.37 10.2515H138.678L131.986 29.6152L125.295 10.2515Z" fill="black"/>
      <rect y="10.2515" width="99.6662" height="5.69521" fill="black"/>
      <path d="M194.207 27.0551H172.992C174.416 32.6056 182.532 35.5956 188.939 30.4699L192.356 34.0294C182.104 44.281 167.582 37.4467 166.442 26.9108C163.976 4.10248 197.197 3.70254 194.207 27.0551ZM172.992 21.9274H187.657C187.657 19.6493 185.506 15.9474 180.823 15.9474C174.843 15.9474 173.562 19.9341 172.992 21.9274Z" fill="black"/>
      <path d="M266.809 27.0551H245.594C247.019 32.6056 255.134 35.5956 261.541 30.4699L264.959 34.0294C254.707 44.281 240.184 37.4467 239.045 26.9108C236.579 4.10248 269.799 3.70254 266.809 27.0551ZM245.595 21.9274H260.26C260.26 19.6493 258.108 15.9474 253.426 15.9474C247.446 15.9474 246.164 19.9341 245.595 21.9274Z" fill="black"/>
      <path d="M205.17 0H197.909V38.4427H205.17V0Z" fill="black"/>
      <path d="M215.706 10.2514H208.302L220.12 38.4427H226.812L238.629 10.2514H231.368L223.537 29.7575L215.706 10.2514Z" fill="black"/>
    </svg>
  );
};

export default PlannerLogo;
