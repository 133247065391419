import React from 'react';
import { IconProps } from './types';

const IconLaunch: React.FC<IconProps> = props => (
  <svg
    {...props}
    aria-labelledby='launch'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 18 18'>
    <g>
      <path d="M17.2235 0.921503C17.2087 0.854706 17.1567 0.802753 17.0936 0.784198C14.919 0.253534 9.8981 2.14611 7.17799 4.86623C6.69185 5.34865 6.25025 5.87189 5.85689 6.42853C5.01822 6.35432 4.17955 6.4174 3.46334 6.72912C1.44459 7.61603 0.858259 9.93537 0.691267 10.9299C0.657869 11.1229 0.787751 11.3084 0.984431 11.3418C1.01783 11.3455 1.05123 11.3492 1.08463 11.3455L4.32428 10.9893C4.32799 11.2342 4.34283 11.4791 4.36881 11.7203C4.38365 11.8873 4.46158 12.0469 4.58033 12.1656L5.83463 13.4162C5.95338 13.535 6.11295 13.6129 6.27994 13.6278C6.52115 13.6537 6.76236 13.6686 7.00728 13.6723L6.65103 16.9082C6.62877 17.1049 6.77349 17.283 6.97017 17.3016C7.00357 17.3053 7.03697 17.3053 7.06666 17.2979C8.06119 17.1383 10.3842 16.552 11.2674 14.5332C11.5792 13.817 11.6422 12.982 11.5717 12.1471C12.1321 11.7537 12.6553 11.3084 13.1378 10.826C15.8653 8.1133 17.7467 3.20373 17.2235 0.921503ZM12.9299 7.58635C12.236 8.28029 11.1079 8.284 10.4139 7.58635C9.71627 6.8924 9.71627 5.76428 10.4139 5.06662C11.1079 4.36896 12.236 4.36896 12.9337 5.06662C13.6276 5.76428 13.6276 6.8924 12.9299 7.58635Z"/>
      <path d="M5.74922 14.3216C5.54512 14.5257 5.21855 14.6037 4.8252 14.6742C3.94199 14.8226 3.1627 14.0619 3.32227 13.1712C3.38164 12.8335 3.56348 12.3585 3.6748 12.2472C3.73789 12.1841 3.7416 12.0802 3.67852 12.0171C3.64141 11.98 3.59316 11.9615 3.54121 11.9689C3.04766 12.0283 2.5875 12.2546 2.23867 12.6035C1.36289 13.4792 1.28125 16.7189 1.28125 16.7189C1.28125 16.7189 4.52461 16.6373 5.39668 15.7615C5.74922 15.4089 5.97187 14.9525 6.03125 14.4552C6.04609 14.3031 5.85684 14.2103 5.74922 14.3216Z"/>
    </g>
  </svg>
);

export default IconLaunch;