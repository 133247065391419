import React from 'react';
import { IconProps } from './types';

const IconRoadmap: React.FC<IconProps> = props => (
  <svg
    {...props}
    aria-labelledby='roadmap'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 22 22'>
    <g>
      <path d="M19.6523 17.495H3.17898V3.55248C3.17898 3.02791 2.75564 2.59998 2.22647 2.59998C1.7019 2.60458 1.27856 3.03251 1.27856 3.55708V18.4475C1.27856 18.972 1.7019 19.4 2.23107 19.4H19.6569C20.1814 19.4 20.6094 18.9766 20.6094 18.4475C20.6048 17.9229 20.1814 17.495 19.6523 17.495Z"/>
      <path d="M5.49825 6.56639H9.26226C9.95248 6.56639 10.5093 6.00961 10.5093 5.31939C10.5093 4.62917 9.95248 4.07239 9.26226 4.07239H5.49825C4.80803 4.07239 4.25125 4.62917 4.25125 5.31939C4.24665 6.00961 4.80803 6.56639 5.49825 6.56639Z"/>
      <path d="M6.82324 9.79211C6.82324 10.4823 7.38002 11.0391 8.07024 11.0391H13.6933C14.3835 11.0391 14.9403 10.4823 14.9403 9.79211C14.9403 9.10188 14.3835 8.5451 13.6933 8.5451H8.07024C7.38002 8.5451 6.82324 9.10649 6.82324 9.79211Z"/>
      <path d="M13.3162 14.2187V14.3246C13.3162 14.9826 13.8499 15.5209 14.5125 15.5209H17.867C18.525 15.5209 19.0634 14.9872 19.0634 14.3246V14.2187C19.0634 13.5607 18.5296 13.0223 17.867 13.0223H14.5125C13.8545 13.0223 13.3162 13.5561 13.3162 14.2187Z"/>
    </g>
  </svg>
);

export default IconRoadmap;
